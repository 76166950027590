import "./jquery.inputmask.bundle.min.js";
const RECAPTCHA_PUBLIC_KEY = "6Leb_tUUAAAAACwP56ObeP0DnLdhRCaXhGUTdJT4";

var CustomScripts = (function() {

    /**---------------------------------------
     * -----Обработчик отправки формы---------
     * ---------------------------------------
     */
    function submitForm(){
        $('form:not(#search_form)').on('submit', function(e){
            e.preventDefault();

            var $this = $(e.target),
                form = new FormData(e.target);

            grecaptcha.ready(function() {
                grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, {
                    action: "homepage"
                }).then(function(token) {
                    form.append('token', token);
                    
                    $.ajax({
                        url: $this.attr('action'),
                        type: $this.attr('method'),
                        contentType: false,
                        processData: false,
                        cache: false,
                        data: form,
                        dataType: 'JSON',
                        success: function(data, text, XHR){
                            console.log(data);
                            switch (data.status) {
                                case 'success':
                                    $this[0].reset();
                                    
                                    new Noty({
                                        theme: 'metroui',
                                        type: data.status,
                                        layout: 'topRight',
                                        text: data.message,
                                        timeout: 3000
                                    }).show();

                                    if( $this.attr('id') == 'sidebar__order_form' ){
                                        $('#headerOrderButton').trigger('click');
                                        $this.prev().animate({
            
                                            transition: 'opacity 0.5s ease-in-out',
                                            opacity: 0
                        
                                        }, 250, function(){
                                
                                            $(this).html('Заявка<br/>отправлена');
                        
                                        }).delay(250).animate({
                        
                                            transition: 'opacity 0.25s ease-in-out',
                                            opacity: 1
                        
                                        });
                                    }
                                break;
                                default:
                                    new Noty({
                                        theme: 'metroui',
                                        type: data.status,
                                        layout: 'topRight',
                                        text: data.message,
                                        timeout: 3000
                                    }).show();
                                break;
                            }
                        },
                        error: function(data){
                            console.log(data);
                            new Noty({
                                theme: 'metroui',
                                type: 'error',
                                layout: 'topRight',
                                text: data,
                                timeout: 3000
                            }).show();
                        }
                    })
                })
            });
        })
    };

    /**---------------------------------------
     * -------Ajax загрузка проектов----------
     * ---------------------------------------
     */
    function loadProjects(){
        $('#load_projects').on('click', function(e){
            e.preventDefault();

            var $button = $(this),
                data_array = {
                    offset : $button.data('offset'),
                    total : $button.data('total')
                };

            if( $button.data('subdivision') )
                data_array['subdivision'] = $button.data('subdivision');

            console.log(data_array);
            $.ajax({
                url: '/system/system_projects_ajax/',
                type: 'POST',
                dataType: 'JSON',
                data: data_array,
                success: function(data, text, XHR){
                    if( data ){
                        var items = '';
                        
                        data.items.forEach(function( item ){
                            items += '<a href="' + item.Link + '" class="cols__three_single-col global__content_text">' +
                                        '<div class="projects-swiper__image background-image" style="background-image: url(' + item.Image + ')"></div>' +
                                        '<p>' + item.Title + '</p>' +
                                        '<p><span>Заказчик: ' + item.Client + '</span></p>' +
                                     '</a>';
                        });

                        $( items ).appendTo( $button.prev() ).slideDown('slow').queue(function(){
                            $(this).css('display', 'flex');
                        });
                        
                        if( data.offset  >= data_array['total'] ){
                            $button.fadeOut(500, function(){
                                $(this).remove();
                            });
                        }else{
                            $button.data( 'offset', data.offset );
                        }
                        
                        
                    }
                },
                error: function(data){
                    console.log(data);
                }
            })
        });
    }

    /**---------------------------------------
     * -------Утилитарные функции-------------
     * ---------------------------------------
     */

    function miscFunctions(){

        /**---------------------------------------
         * -------Маска для ввода телефона--------
         * ---------------------------------------
         */

        $("input[type=tel]").inputmask("mask", {

            mask: "8 (999) 999-99-99",
            placeholder: "8 (***) ***-**-**",
            showMaskOnHover: 1,
            showMaskOnFocus: !1,
            removeMaskOnSubmit: !0, 
            clearMaskOnLostFocus: !0,
            clearIncomplete: !0

        });

        /**---------------------------------------
         * ----Обработка загрузки файла в поле----
         * ---------------------------------------
         */

        $('#file').on('change', function(){
            var $field = $(this),
                fileName = $field.val().split(/(\\|\/)/g).pop();

            if( fileName ){
                $('.sidebar__order_add-file').animate({

                    transition: 'opacity 0.5s ease-in-out',
                    opacity: 0

                }, 250, function(){
        
                    $(this).find('.add-text').html( 'Удалить файл' );
                    $(this).find('.extra-text').html( fileName );

                }).delay(500).animate({

                    transition: 'opacity 0.25s ease-in-out',
                    opacity: 1

                });
            }else{
                $('.sidebar__order_add-file').animate({

                    transition: 'opacity 0.25s ease-in-out',
                    opacity: 0

                }, 250, function(){
        
                    $(this).find('.add-text').html( 'Прикрепить файл' );
                    $(this).find('.extra-text').html( 'Не более 3 мб' );

                }).delay(250).animate({
                    
                    transition: 'opacity 0.5s ease-in-out',
                    opacity: 1

                });
            }

            console.log( fileName );
        });
    }


    return {
        submitForm : submitForm,
        loadProjects : loadProjects,
        miscFunctions : miscFunctions
    };
    
})();

export default CustomScripts;