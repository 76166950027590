// import $ from 'jquery';
import PageTransitions from './pagetransitions.js';
// import * as Hammer from 'hammerjs';
//
// window.Hammer = Hammer.default;
//
// var myElement = document.getElementById('bodyswipe');
// var hammertime = new window.Hammer(myElement);
//
// hammertime.get('pan').set({
// 	direction: Hammer.DIRECTION_VERTICAL,
// 	threshold: 100,
// });
//
// hammertime.on("panup pandown", function(ev) {
//
// 	if ($( window ).width() > 1024){
// 		var distance = 500;
// 	}else{
// 		var distance = 10;
// 	}
//
// 	console.log(ev.distance);
//
// 	if (ev.distance > distance){
//
// 		if ( ev.type === 'panup' &&  ($( window ).width() >= 768)){
// 			PageTransitions.nextPage(22, null);
// 	    } else if ( ev.type === 'pandown' &&  ($( window ).width() >= 768) ){
// 	    	PageTransitions.prevPage(21, null);
// 	    }
// 	}
// });

var mousewheelevt=(/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel" //FF doesn't recognize mousewheel as of FF3.x
 
if (document.attachEvent) //if IE (and Opera depending on user setting)
    document.attachEvent("on"+mousewheelevt, function(e){
    	wheel(e)
    })
else if (document.addEventListener) //WC3 browsers
    document.addEventListener(mousewheelevt, function(e){
    	wheel(e)
    }, false)


function wheel(e){
	// console.log(e);
	if ($(window).width() >= 320){
		if(e.wheelDelta / 120 > 0) {
	        PageTransitions.prevPage(21, null);
	    }
	    else{
	        PageTransitions.nextPage(21, null);

	    }
	}
}
	




$( window ).resize(function() {
	PageTransitions.init();
});

$('.dots-page span').click(function(){
	$('.dots-page span.active').removeClass('active');
	$(this).addClass('active');
	var idSlide = $(this).attr('data-slide');
	// console.log(idSlide);
	PageTransitions.nextPage(21, idSlide);
})

$('.menu__item').click(function(){
	$('.menu__item.active').removeClass('active');
	$(this).addClass('active');
	var idSlide = $(this).attr('data-slide');

	if ($(window).width() >= 320){
		PageTransitions.nextPage(21, idSlide);
	}else{
        PageTransitions.nextPage(21, idSlide);
		//$('a[href^="#"]').click(function(){
	        // var el = $(this).attr('href');
	        // $('body').animate({
	        //     scrollTop: $(el).offset().top}, 2000);
	        // return false; 
		//});
		// var offset = $('div[data-no-slide="'+idSlide+'"]').offset().top;
		// console.log(offset);
		// console.log($('div[data-no-slide="'+idSlide+'"]'));

		// $("html, body").animate({scrollTop: offset}, 1500);
	}
})

