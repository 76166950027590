import $ from 'jquery';
// $ = require('jquery')
// import "../css/main.scss";
import "./pagetransitions.js";
import "./scroll.js";
import "./magnific.popup";
import "./swiper";
import "./customScripts.js";
import PageTransitions from "./pagetransitions";
import CustomScripts from "./customScripts";
import Swiper from "swiper";
import anime from 'animejs'
import "./anime.js";

function clickingActions() {
    /* ⇓ animated-burger ⇓ */
    $('#headerOrderButton, #imageDescriptionAction').click(function(){
        $('#headerOrder').toggleClass("header__order_open");
        $("body, html").toggleClass("no-scroll");
        var TLParamsInheritance = anime.timeline({
            targets: '.sidebar__order_row, .sidebar__order_content .sidebar__order_inside h2, .sidebar__order_content .sidebar__order_inside .h2',
            delay: 1000,
            duration: 700,
            easing: 'easeOutExpo',
            direction: 'normal',
            loop: false
        });

        TLParamsInheritance
            .add({
                translateX: -200,
                opacity: 0,
                duration: 0,
                delay: 0
            })
            .add({
                opacity: {
                    value: 1,
                    delay: 700,
                    duration: 3000,
                },
                translateX: {
                    value: 0,
                    delay: function(el, i) { return i * 50 + 500 },
                    duration: 700,
                },
            });
    });
    /* ⇑ animated-burger ⇑ */
}

function stickyHeader() {
    /* ⇓ stickyHeader ⇓ */
    window.onscroll = function() {doScroll()};

    var header = document.getElementById("pageHeader");
    var sticky = header.offsetTop + 80;

    function doScroll() {
        if (window.pageYOffset > sticky) {
            $("#pageHeader").addClass("page-header__sticky");
        } else {
            $("#pageHeader").removeClass("page-header__sticky");
        }
    }
    /* ⇑ stickyHeader ⇑ */
}

function goTop() {
    /* ⇓ scroll-to-top ⇓ */
    $("#scrollTopPage").click(function() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });
    /* ⇑ scroll-to-top ⇑ */
}

function animatedBurger() {
    /* ⇓ animated-burger ⇓ */
    var forEach=function(t,o,r){if("[object Object]"===Object.prototype.toString.call(t))for(var c in t)Object.prototype.hasOwnProperty.call(t,c)&&o.call(r,t[c],c,t);else for(var e=0,l=t.length;l>e;e++)o.call(r,t[e],e,t)};

    var hamburgers = document.querySelectorAll(".hamburger");
    if (hamburgers.length > 0) {
        forEach(hamburgers, function(hamburger) {
            hamburger.addEventListener("click", function() {
                this.classList.toggle("is-active");
                $('#navBurger').toggleClass("hamburger_open");
                $('body, html').toggleClass("no-scroll");
                $('#navMenu').toggleClass("sidebar__menu_open");
            }, false);
        });
    }
    /* ⇑ animated-burger ⇑ */
}
function mainMenuOrder(){
    $('#mainMenuOrderButton').on('click', function(){
        $('.hamburger.hamburger--slider-r').trigger('click');
        $("body, html").toggleClass("no-scroll");

        setTimeout(function(){
            $('#headerOrder').toggleClass("header__order_open");
            var TLParamsInheritance = anime.timeline({
                targets: '.sidebar__order_row, .sidebar__order_content .sidebar__order_inside h2, .sidebar__order_content .sidebar__order_inside .h2',
                delay: 1000,
                duration: 700,
                easing: 'easeOutExpo',
                direction: 'normal',
                loop: false
            });

            TLParamsInheritance
                .add({
                    translateX: -200,
                    opacity: 0,
                    duration: 0,
                    delay: 0
                })
                .add({
                    opacity: {
                        value: 1,
                        delay: 700,
                        duration: 3000,
                    },
                    translateX: {
                        value: 0,
                        delay: function(el, i) { return i * 50 + 500 },
                        duration: 700,
                    },
                });
        }, 500);
    })

}
function frontpageThumbing() {
    /* ⇓ frontpage-thumbing ⇓ */
    $('#frontpage__thumb_down').click(function(){
        PageTransitions.nextPage(22, null);
    });
    $('#frontpage__thumb_up').click(function(){
        PageTransitions.prevPage(22, null);
    });
    /* ⇑ frontpage-thumbing ⇑ */

    document.addEventListener("touchmove", ScrollStart, false);
    document.addEventListener("scroll", Scroll, false);

    function ScrollStart() {
        PageTransitions.nextPage(22, null);
        //start of scroll event for iOS
    }

    function Scroll() {
        PageTransitions.prevPage(22, null);
        //end of scroll event for iOS
        //and
        //start/end of scroll event for other browsers
    }

}

function magnificPopupInit() {
    /* ⇓ magnific-popup ⇓ */
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 320,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false
    });
    /* ⇑ magnific-popup ⇑ */
}

function singleImagePopup (){
    $('.image-popup-vertical-fit').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }

    });

    $('.image-popup-fit-width').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        image: {
            verticalFit: false
        }
    });

    $('.image-popup-no-margins').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        closeBtnInside: false,
        fixedContentPos: true,
        mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
        image: {
            verticalFit: true
        },
        zoom: {
            enabled: true,
            duration: 300 // don't foget to change the duration also in CSS
        }
    });
}

function swiper (){
    //var mySwiper =
    new Swiper('.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
        },
    });
}

function swiperScans(){
    new Swiper('.feedback-scans', {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            993: {
                slidesPerView: 2,
            },
            480: {
                slidesPerView: 1.5,
            },
        }
    });
}

function popupGallary (){
    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Загрузка изображения #%curr%, пожалуйста подождите...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
        },
        image: {
            tError: 'Не удалось загрузить <a href="%url%">Изображение №%curr%</a>',
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        }
    });
}

function smoothScrollSection(){
    $("#scrollPageDown").click(function(e) {
        e.preventDefault();

        var position = $($(this).attr("href")).offset().top;

        $("body, html").animate({
            scrollTop: position
        } /* speed */ );
    });
}

function projectsSwiper (){
    //var mySwiper =
    new Swiper('.projects-swiper-container', {
        slidesPerView: 2,
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            767: {
                slidesPerView: 1,
            },
        },
    });
}

function contactsPageEmployee(){
    $('.wrapper .table__row').click(function(){
        this.classList.toggle("opened-info");
    });
}

function pageTabs(){
    $('#pageTabsWrapper').click(function () {
        $('#pageTabsWrapper').toggleClass('opened-tabs');
    });
}

$(document).ready(function () {
    clickingActions();
    stickyHeader();
    animatedBurger();
    frontpageThumbing();
    magnificPopupInit();
    goTop();
    singleImagePopup();
    swiper();
    swiperScans();
    popupGallary();
    smoothScrollSection();
    projectsSwiper();
    contactsPageEmployee();
    pageTabs();
    mainMenuOrder();
    CustomScripts.submitForm();
    CustomScripts.loadProjects();
    CustomScripts.miscFunctions();
});
